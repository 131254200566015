import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../style/philosophy.css";

function Philosophy() {
  return (
    <div className="philosophyContainer pb-1">
      <div className="container">
        <h2 class="services-header philosophyTitle">THE ROYAL HUB</h2>
        <div class="d-md-flex justify-content-between align-items-center">
          <div class="services-secondsub">
            <p className="philosophyTitle">Treasure Your Memories </p>
          </div>
          <div>
            <a href="/about" className="philosophyTitle">
              About Us
              <FontAwesomeIcon icon={faArrowRight} className="arrowIcon" />
            </a>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="imagegalary">
              <div class="image1">
                <img
                  src="/static/bookingSection1.JPG"
                  alt="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
                  class="innerImg"
                />
              </div>
              <div class="image2">
                <img
                  src="/static/bookingSection2.JPG"
                  alt="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
                  class="innerImg"
                />
              </div>
              <div class="image3">
                <img
                  src="/static/bookingSection3.JPG"
                  alt="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
                  class="innerImg"
                />
              </div>
            </div>
          </div>

          <div class="col-md-7 flex-center">
            <p class="booknowHeader philosophyTitle">
              Entertainment and Event Management Enjoy Private Mini Theatre,
              Decoration with Delicious Food
            </p>
            <p className="booknowPara philosophyTitle">
              'The Royal Hubs' welcomes you to the world of Celebrations"
            </p>
            <a href="/booknow" class="button">
              Book Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Philosophy;
