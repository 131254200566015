import React from "react";
import Carousel from "./Carousel";
import Marquee from "./Marquee";
import Philosophy from "./Philosophy";
import Service from "./Service";
import Card from "./Card";
import HomeCarousel from "./HomeCarousel";


function Home() {
  return (
    <div>
      <Carousel />
      <Service />
      <Card />
      <Philosophy />
      <Marquee />
      <HomeCarousel></HomeCarousel>
    </div>
  );
}

export default Home;
