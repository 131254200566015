import React, { useRef, useState } from "react";
import "../App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import emailjs from "@emailjs/browser";

let initialData = {
  name: "",
  mobile: "",
  email: "",
  message: "",
};
function Contact() {
  const [data, setData] = useState(initialData);
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data);
    emailjs
      .sendForm(
        "service_2gf4c1l",
        "template_8gxfzfh",
        form.current,
        "XsikZIL6iuwUrANSw"
      )
      .then(
        (result) => {
          alert(
            "Thanks for booking with The Royal Hubs, We will get back to you soon for confirmation and clarifications"
          );
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="container-fluid">
      <div className="heroImage">
        <img className="contact-caraousel-img" src="/static/contact.JPG" />
        <div className="contact-title">
          <h2 className="contact-caraousel-title">Contact Us</h2>
          <p className="contact-carousel-para">
            ---- Scroll Down To Book Your Event ----
          </p>
        </div>
      </div>

      <div className="container mt-3">
        <div class="row p-4 border">
          <div class="col-md-6">
            <h1 class="contact-body-head mb-0">Contact Us</h1>
            <div className="underLine w-75"></div>
            <h4 class="contact-body-subtitle">
              Feel free to get in touch with us
            </h4>
            <h2 className="contact-body-secondMain">Call Us</h2>
            <h5 className="contact-body-mobile">+91 6360845880</h5>
            <h5 className="contact-body-address">
            No 8, 2nd Floor, Deepak Raj Complex, Christ University Main
                Road, Rukmini Nagar, Nagasandra Post, Bengaluru-560073 <br/> Landmark: Opp. Sarojini Hospital
                & above D-Mart 
            </h5>
            <div class="d-flex fs-1 gap-4 p-4">
              <a
                href="https://www.instagram.com/theroyalhubs.in"
                className="midiaIcon"
              >
              <div  style={{height:"40px",width:"40px"}}>
              <img src="/static/instagramlogo.png"     style={{height:"100%",width:"100%"}} alt="" />
              </div>
              </a>
 
              <a
                href="https://www.facebook.com/theroyalhubs?mibextid=ZbWKwL"
                className="midiaIcon"
              >
              <div  style={{height:"40px",width:"40px"}}>
              <img src="/static/facebooklogo.png"     style={{height:"100%",width:"100%"}} alt="" />
              </div>
              </a>
              <a
                href="https://www.youtube.com/@TheRoyalHub-oh6uf"
                className="midiaIcon"
              >
                <div  style={{height:"40px",width:"40px"}}>
              <img src="/static/youtubelogo.png"     style={{height:"100%",width:"100%"}} alt="" />
              </div>
              </a>
            </div>
          </div>

          <div class="col-md-6">
            <form id="contact-form" ref={form} onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Name"
                className="contact-form-input"
                id="name"
                name="name"
                onChange={(e) => {
                  setData((pre) => ({
                    ...pre,
                    name: e.target.value,
                  }));
                }}
              />
              <input
                type="number"
                placeholder="Mobile Number"
                className="contact-form-input"
                id="phone"
                name="mobile"
                onChange={(e) => {
                  setData((pre) => ({
                    ...pre,
                    mobile: e.target.value,
                  }));
                }}
              />
              <input
                type="email"
                placeholder="Email (optional)"
                className="contact-form-input"
                name="email"
                id="email"
                onChange={(e) => {
                  setData((pre) => ({
                    ...pre,
                    email: e.target.value,
                  }));
                }}
              />

              <textarea
                placeholder="Your Message"
                className="contact-form-input"
                name="message"
                id="message"
                onChange={(e) => {
                  setData((pre) => ({
                    ...pre,
                    message: e.target.value,
                  }));
                }}
              ></textarea>
              <button className="button" type="submit">
                Request a call
              </button>
            </form>
          </div>
        </div>
      </div>

      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.917345336189!2d77.5050152!3d13.040932999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d0051b57eb1%3A0xd44196986b9c0d1d!2sThe%20Royalhubs!5e0!3m2!1sen!2sin!4v1705154077384!5m2!1sen!2sin"
          className="locationIframe"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
