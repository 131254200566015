import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
// import './styles.css';
import { EffectCards } from "swiper/modules";
import data from "./coupleTheatrePhotos.json";
import data2 from "./familyTheatrePhotos.json";
import data3 from "./royalTheatrePhotos.json";

const HomeCarousel = () => {
  return (
    <>
      <div className="container">
        <div className="row mt-5 mb-5">
          <h2 class="services-header philosophyTitle text-center pb-4">
            Image Gallery
          </h2>
          <div className="col-lg-4 col-12">
            <h3 class="services-secondsub text-center fs-6">
              -- Swipe Left/Right --
            </h3>
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper"
            >
              {data.gallery.map((item) => {
                return (
                  <>
                    <SwiperSlide>
                      <img
                        src={item}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />{" "}
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
            <h3 class="services-secondsub text-center mt-3">Couple Theatre</h3>
          </div>
          <div className="col-lg-4 col-12">
            <h3 class="services-secondsub text-center fs-6 ">
              -- Swipe Left/Right --
            </h3>
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper"
            >
              {data2.gallery.map((item) => {
                return (
                  <>
                    <SwiperSlide>
                      <img
                        src={item}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />{" "}
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
            <h3 class="services-secondsub text-center mt-3">Family Theatre</h3>
          </div>
          <div className="col-lg-4 col-12">
            <h3 class="services-secondsub text-center fs-6 ">
              -- Swipe Left/Right --
            </h3>
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper"
            >
              {data3.gallery.map((item) => {
                return (
                  <>
                    <SwiperSlide>
                      <img
                        src={item}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />{" "}
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
            <h3 class="services-secondsub text-center mt-3">Royal Theatre</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCarousel;
