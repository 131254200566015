import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { faPlus } from "@fortawesome/free-solid-svg-icons";

function Service() {
  return (
    <div style={{ backgroundColor: "#f5f1ca" }}>
      <section class="container mt-0 ">
        <div>
          <h2 class="services-header">Entertainment and Event Management</h2>
          <h2 class="services-header">Enjoy Private Mini Theatre,</h2>
          <h2 class="services-header">Decoration with Delicious Food</h2>

          <div className="underLine"></div>
          <p className="services-secondsub">Treasure your Memories</p>

          <div class="container">
            <div class="row mt-3">
              <div class="d-flex gap-3 col-md-4">
                <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                <p class="service_name">Private Theatre</p>
              </div>
              <div class="d-flex gap-3 col-md-4">
                <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                <p class="service_name">Decorations</p>
              </div>
              <div class="d-flex gap-3 col-md-4">
                <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                <p class="service_name">Delicious Food</p>
              </div>
              <div class="d-flex gap-3 col-md-4">
                <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                <p class="service_name">Birthday Celebrations</p>
              </div>
              <div class="d-flex gap-3 col-md-4">
                <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                <p class="service_name">Anniversary</p>
              </div>
              <div class="d-flex gap-3 col-md-4">
                <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                <p class="service_name">Parties</p>
              </div>
              <div class="d-flex gap-3 col-md-4">
                <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                <p class="service_name">Proposals</p>
              </div>
              <div class="d-flex gap-3 col-md-4">
                <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                <p class="service_name">Bride To Be</p>
              </div>
              <div class="d-flex gap-3 col-md-4">
                <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                <p class="service_name">Mom To Be</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Service;
