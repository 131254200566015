import React from 'react'

function TermsCond() {
  return (
    <div>

<div className="container-fluid">
<div style={{ height: "80px",backgroundColor:"#27282b"}}></div>
       <div className='container p-4 mt-4 mb-4 '>
       <h2> GENERAL INSTRUCTIONS</h2>
       <p>Last Updated :20/01/2024</p>

       <p>1. Ensure on time arrival according to the slot.</p>
       <p>2. Free entry for childrens below 5years.</p>
        <p>3.Your personal OTT subscriptions are required to access OTT accounts and rented movies.</p>
        <p>4. <b>Smoking and the Consumption of alcohol are prohibited.</b></p>
        <p>5. Identification proof (Aadhar, pan card, driving pcense) is required.</p>
        <p>6.Those who are under 18 years are not permitted to book theatres.</p>
        <p>7. Couples under the age 18 are not permitted.</p>
        <p>8. <b>It is Strictly prohibited to use fire, party poppers, snow sprays and similar items in the theatre.</b></p>
        <p>9. If any DAMAGE caused to theater, including decorative materials, lights etc. will have to be reimbursed.</p>
        <p>10.Guests are requested to maintain hygiene inside the theater.</p>


       </div>
    </div>
    </div>
  )
}

export default TermsCond