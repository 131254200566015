import React from "react";

const Booknow = () => {
  return (
    <>
    <div style={{ height: "80px",backgroundColor:"#27282b"}}></div>
      <div className="container-fluid" >
        <div className="d-flex justify-content-center mb-5 " style={{marginTop:"2%"}}>
        <h2 className="text-center w-100 colorGold" >
              SELECT YOUR PRIVATE THEATRE
            </h2>
            
        
        </div>

        <div className="container">
          <div
            className="row align-items-md-center justify-content-center colorGold"        
          >
            <div className="col-md-6 p-0 order-0" style={{ height: "350px" }}>
              <img
                src="/static/booknow1.jpg"
                className="innerImg"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="col-md-6 p-0 order-1">
              <div className="h-100 ps-5 pe-5 p-3">
                <h2>1. Couple Theater</h2>
                <ul>
                  <li>Rs.1499(including Decorations)/3hrs.</li>
                  <li>Without decorations not available.</li>
                  <li>Only for 2 persons allowed.</li>
                  <li>2-seater recliners.</li>
                  <li>Need to use personal OTT channels.</li>
                </ul>
                <div className="d-flex justify-content-center">
                  <a href="/couplebookingform" class="button px-3 py-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row align-items-md-center justify-content-center"
            style={{ color: "#918004" }}
          >
            <div
              className="col-md-6 p-0 order-lg-1"
              style={{ height: "350px" }}
            >
              <img
                src="/static/booknow2.JPG"
                className="innerImg"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="col-md-6 p-0 order-lg-0 ">
              <div className="h-100 ps-5 pe-5 p-3">
                <h2>2. Family Theater</h2>
                <ul>
                  <li>Rs.999 Rs for 4 persons, without decorations.</li>
                  <li>with decoration Rs.699 extra.</li>
                  <li>Above 4 persons, Rs.299 extra/person.</li>
                  <li>Total 6 persons allowed.</li>
                  <li>Free for below 5 years children.</li>
                  <li>
                    Only 5 seaters, front 2-recliners and 3-seater back sofa and
                    1-bean bag.
                  </li>
                  <li>Need to use personal OTT channels.</li>
                </ul>
                <div className="d-flex justify-content-center">
                  <a href="/familybookingform" class="button px-3 py-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row align-items-md-center justify-content-center"
            style={{ color: "#918004" }}
          >
            <div className="col-md-6 p-0 order-0" style={{ height: "350px" }}>
              <img
                src="/static/booknow3.JPG"
                className="innerImg"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="col-md-6 p-0 order-1">
              <div className="h-100 ps-5 pe-5 p-3">
                <h2>3. Royal Theater</h2>
                <ul>
                  <li>Rs.1499 for 6 persons, without decorations.</li>
                  <li>With decorations Rs.699 extra.</li>
                  <li>Above 6 persons Rs.299 extra/person.</li>
                  <li>Total 12 persons allowed.</li>
                  <li>Free below 5years children.</li>
                  <li>Only 10-recliners seater and 2-bean bags.</li>
                  <li>Need to use personal OTT channels.</li>
                </ul>
                <div className="d-flex justify-content-center">
                  <a href="/royalbookingform" class="button px-3 py-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booknow;
