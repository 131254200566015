import React from "react";
import "../style/cardStyle.css";

const Card = () => {
  return (
    <>
      <div className="bgDark p-3">
        <div className="container">
          <h3 class="fs-2 text-center colorLightGold">Select Your Theater</h3>
          <div className="row align-items-md-center pt-2 pb-2 justify-content-between colorLightGold">
            <div className="col-lg-4 col-md-5 p-2 text-center  flex-center">
              <div className="cardBody">
                <div>
                  <h4>1. Couple Theater</h4>
                </div>
                <div className="cardImage">
                  <img
                    src="/static/booknow1.jpg"
                    className="innerImg"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="h-100 p-2">
                  <p className="mb-0 colorLightGold">
                    Rs.1499 (including decoration) decoration is mandatory. 
                    for 2 persons/3hrs, 2 seater recliners
                  </p>
                  <p className="colorLightGold">
                   Only 2 persons allowed
                  </p>

                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <i class="bi bi-people fs-2"></i>
                    <p className="colorLightGold pt-3">2 People</p>
                    <a
                      href="/couplebookingform"
                      class="button px-3 py-2 "
                      style={{ fontSize: "12px" }}
                    >
                      Check Slots
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-5 p-2 text-center  flex-center">
              <div className="cardBody">
                <div>
                  <h4>2. Family Theatre</h4>
                </div>
                <div className="cardImage">
                  <img
                    src="/static/booknow2.JPG"
                    className="innerImg"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="h-100 p-2">
                  <p className="mb-0 colorLightGold">
                    Rs.999 for 4 persons/3hrs, decoration -Rs699 extra.
                  </p>
                  <p className="colorLightGold">
                   6 Persons allowed (extra 299/person)
                  </p>
                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <i class="bi bi-people fs-2"></i>
                    <p className="colorLightGold pt-3">2-6 People</p>
                    <a
                      href="/familybookingform"
                      class="button px-3 py-2 "
                      style={{ fontSize: "12px" }}
                    >
                      Check Slots
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-5 p-2 text-center  flex-center">
              <div className="cardBody">
                <div>
                  <h4>3. Royal Theatre</h4>
                </div>
                <div className="cardImage">
                  <img
                    src="/static/booknow3.JPG"
                    className="innerImg"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="h-100 p-2">
                  <p className="mb-0 colorLightGold">
                    Rs.1499 for 6 persons/3hrs, decoration-Rs.699 extra.
                  </p>
                  <p className="colorLightGold">
                    12 Persons allowed(extra 299/Person)
                  </p>
                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <i class="bi bi-people fs-2"></i>
                    <p className="colorLightGold pt-3">2-12 People</p>
                    <a
                      href="/royalbookingform"
                      class="button px-3 py-2 "
                      style={{ fontSize: "12px" }}
                    >
                      Check Slots
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
