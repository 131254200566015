import "./App.css";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Gallerys from "./Components/Gallerys";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import About from "./Components/About";
import Refund from "./Components/Refund";
import Booknow from "./Components/Booknow";
import Video from "./Components/video";
import CollapseNavBar from "./Components/CollapseNavBar";
import CoupleTheatreForm from "./Components/CoupleTheatreForm";
import FamilyTheatreForm from "./Components/FamilyTheatreForm";
import RoyalTheatreForm from "./Components/RoyalTheatreForm";
import FoodMenu from "./Components/FoodMenu";
import TermsCond from "./Components/Terms&Cond";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsOfUse from "./Components/TermsOfUse";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/booknow" element={<Booknow />} />
          <Route path="/gallery" element={<Gallerys />} />
          <Route path="/videos" element={<Video />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/couplebookingform" element={<CoupleTheatreForm/>} />
          <Route path="/familybookingform" element={<FamilyTheatreForm/>} />
          <Route path="/royalbookingform" element={<RoyalTheatreForm/>} />
          <Route path="/foodmenu" element={<FoodMenu/>} />
          <Route path="/generalinstructions" element={<TermsCond/>} />
          <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
          <Route path="/termsofuse" element={<TermsOfUse/>} />
        </Routes>
        {/* <Navbar /> */}
        <CollapseNavBar></CollapseNavBar>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
