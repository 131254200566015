import React from "react";

function FoodMenu() {
  return (
    <div>
      <div style={{ height: "80px", backgroundColor: "#27282b" }}></div>

      <div className="container d-flex flex-column align-items-center">
        <img src="/static/pdf1.PNG" className="w-75" alt="" />
        <img src="/static/pdf2.PNG" className="w-75" alt="" />
        <img src="/static/pdf3.PNG" className="w-75" alt="" />
        <img src="/static/pdf4.PNG" className="w-75" alt="" />
        <img src="/static/pdf5.PNG" className="w-75" alt="" />
        <img src="/static/pdf6.PNG" className="w-75" alt="" />
      </div>
    </div>
  );
}

export default FoodMenu;
