import React from 'react';

function Marquee() {
  return (
    <div >
      <div class="marqueeSection">

        <div>
          <marquee
            behavior=""
            direction=""
            scrollamount="15"
            style={{ fontSize: "112px" }}
            class="d-flex align-items-center"
          >
            <p
              className='par_text'
              style={{
                fontFamily: "Segoe UI",
                webkitTextStroke: "2px #f3d706",
                webkitTextfillColor: "transparent",

                padding: "57px",
                fontSize: "6rem",
                fontWeight: "700",
                
              }}

            >
            PRIVATE MINI THEATRE &nbsp; &nbsp;&nbsp; ENTERTAINMENT 
              &nbsp;&nbsp;&nbsp; EVENT MANAGEMENT &nbsp; &nbsp;&nbsp; &nbsp;
              &nbsp;&nbsp; DECORATIONS &nbsp; &nbsp;&nbsp; &nbsp; DELECIOUS FOOD
            </p>
          </marquee>
        </div>
      </div>
    </div>
  )
}

export default Marquee