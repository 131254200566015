import React, { useRef, useState } from "react";
import "../App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import emailjs from "@emailjs/browser"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";


let initialData = {
    name: "",
    mobile: "",
    email: "",
    date: "",
    theatre: "",
    numberOfPeople: "",
    slot: "",
    decoration: "",
    occation: "",
    cake: "",
    message: "",
    partnerNickname: "",
  };

function RoyalTheatreForm() {
    const [royalData, setRoyalData] = useState(initialData); 
    const[isLoading, setIsLoading] = useState(false)
    const Navigate = useNavigate();
    const form = useRef();   
    const notify = () => {toast.success('Your Booking is Successfull', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });}
  
      const fill = ()=>{
        toast.warn('Please Fill Required Details!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark", 
          });
      }
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(royalData);
        if(!royalData.name || !royalData.email || !royalData.mobile || !royalData.date){
          fill()
        }else{
          setIsLoading(true)
          

        emailjs
          .sendForm(
            "service_2gf4c1l",
            "template_8gxfzfh",
            form.current,
            "XsikZIL6iuwUrANSw"
          )
          .then(
            (result) => {
                  setIsLoading(false);
      notify();
      setRoyalData("")
      setTimeout(()=>{
        Navigate("/")
      },3000)
      
            },
            (error) => {
              console.log(error.text);
            }
          );
             /////////////////////////////////////////below is for testing////////////////////////////////////////
      // emailjs
      // .sendForm("service_6cafo6j","template_o52j70h",form.current,"ThB_3SjFT5pTwV_3l").then((result) => {
      //   setIsLoading(false);
      //   notify();
      //   setTimeout(()=>{
      //     Navigate("/")
      //   },3000)
      //   },
      //   (error) => {
      //     console.log(error.text);
      //   }
      // );
    
      /////////////////////////////////////////////////////////////////////////////////
      }
    };


      


  return (
    <>  
    <div >
    <div style={{ height: "15vh",backgroundColor:"#27282b"}}></div>
    <div class="container col-sm-12 col-md-6 p-5 m-auto ">
      <h1 class="contact-body-head"> Royal Theatre Booking Form</h1>

      <div className="d-flex justify-content-end flex-end ">
          <a href="/">
            <button className="colorLightGold px-4 py-2 border rounded-5" style={{backgroundColor:'#918004'}}>Close</button>
          </a>
        </div>
      <form
        id="contact-form"
        ref={form}
        onSubmit={handleSubmit}
        className="text-center"
      >
        <input
          type="text"
          placeholder="Name"
          className="contact-form-input"
          id="name"
          name="name"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              name: e.target.value,
            }));
          }}
        />
        <input
          type="number"
          placeholder="Mobile Number"
          className="contact-form-input"
          id="phone"
          name="mobile"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              mobile: e.target.value,
            }));
          }}
        />
        <input
          type="email"
          placeholder="Email (optional)"
          className="contact-form-input"
          name="email"
          id="email"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              email: e.target.value,
            }));
          }}
        />

        <input
          type="date"
          placeholder="Select Date - "
          className="contact-form-input"
          name="date"
          id="date"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              date: e.target.value,
            }));
          }}
        />

        <select
          name="slot"
          className="contact-form-input"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              slot: e.target.value,
            }));
          }}
        >
          <option>Select the slot/Price</option>
          <option>10:00 AM - 1:00 PM /Rs.1499</option>
          <option>1:30 PM - 4:30 PM /Rs.1499</option>
          <option>5:00 PM - 8:30 PM /Rs.1499</option>
          <option>9:30 PM - 12:30 PM /Rs.1499</option>
        </select>

        <select
          name="theatre"
          className="contact-form-input"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              theatre: e.target.value,
            }));
          }}
        >
          <option>Select Theatre type</option>
          {/* <option>Couple Theatre</option>
          <option>Family Theatre</option> */}
          <option>Royal Theatre</option>
        </select>

        <select
          name="numberOfPeople"
          className="contact-form-input"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              numberOfPeople: e.target.value,
            }));
          }}
        >
          <option>Select Number of People</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
          <option>11</option>
          <option>12</option>
        </select>

        <select
          name="decoration"
          className="contact-form-input"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              decoration: e.target.value,
            }));
          }}
        >
          <option>Select Decoration</option>
          <option>Required</option>
          <option>Not Required(only for Family/Royal Theatre)</option>
        </select>

        <select
          name="occation"
          className="contact-form-input"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              occation: e.target.value,
            }));
          }}
        >
          <option>Select Occation</option>
          <option>Birthday</option>
          <option>Baby Shower</option>
          <option>Anniversary</option>
          <option>Romantic</option>
          <option>Proposals</option>
          <option>Bride To Be</option>
          <option>Mom To Be</option>
          <option>Farewell</option>
        </select>

        <select
          name="cake"
          className="contact-form-input"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              cake: e.target.value,
            }));
          }}
        >
          <option>Select Cake(500gms)</option>
          <option>Chocolate-500</option>
          <option>Black Forest-500</option>
          <option>Pineapple-500</option>
          <option>Butterscotch-500</option>
          <option>Round Red Velvet-600</option>
          <option>Heart Red Velvet-700</option>
          <option>Blue Berry-600</option>
          <option>Death by Chocolate-700</option>
          <option>Chaco Almand-700</option>
          <option>Heart Pinata-900</option>
        </select>

        <textarea
          placeholder="Nickname (max 6 letters)"
          className="contact-form-input"
          name="message"
          id="message"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              message: e.target.value,
            }));
          }}
        ></textarea>

        
{royalData.occation === "Romantic" || royalData.occation === "Anniversary" || royalData.occation === "Proposals"?
         <textarea
          placeholder=" Partners Nickname (max 6 letters)"
          className="contact-form-input"
          name="partnerNickname"
      
          id="partnerNickname"
          onChange={(e) => {
            setRoyalData((pre) => ({
              ...pre,
              partnerNickname: e.target.value,
              
            }));
            
          }}
        ></textarea>:""}

{isLoading ? (<div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              </div>
            ) : (
              <button className="button contact-form-button"  type="submit" >
                Book Now
              </button>
            )}
            <ToastContainer />
      </form>
    </div>
    </div>
  </>
  )
}

export default RoyalTheatreForm