import React from "react";
import "../App.css";

function PrivacyPolicy() {
  return (
    <div>
      <div style={{ height: "80px", backgroundColor: "#27282b" }}></div>
      <div className="container  p-4 mt-4 mb-4 ">
        <h2>Privacy Policy for THE ROYAL HUBS.</h2>
        <p>Last Updated :20/01/2024</p>

        <h4 className="fs-5">1. Introduction</h4>
        <p>
          Welcome to THE ROYAL HUBS. We are committed to protecting your privacy
          and ensuring the security of your personal information. This Privacy
          Policy outlines the types of information we may collect, how we use
          it, and the choices you have concerning your data.
        </p>

        <h3 className="fs-5">2. Information We Collect</h3>
        <p>
          Personal Information: We may collect personal information such as
          names, email addresses, and contact details when you register or
          engage with our website. Usage Data: We collect data about how you
          interact with our website, including pages visited, time spent on the
          site, and other analytics.
        </p>

        <h3 className="fs-5">3. How We Use Your Information</h3>
        <p>
          We use the information we collect for the following purposes: To
          provide and maintain our services. To communicate with you, respond to
          your inquiries, and send relevant updates. To improve our website and
          user experience. To comply with legal obligations.
        </p>

        <h3 className="fs-5">4. Cookies and Similar Technologies</h3>
        <p>
          We use cookies and similar technologies to enhance your experience on
          our website. You can manage your cookie preferences through your
          browser settings.
        </p>

        <h3 className="fs-5">5. Data Sharing</h3>
        <p>
          We may share your information with third parties under the following
          circumstances: With your consent. To comply with legal obligations. To
          provide necessary services or support.
        </p>

        <h3 className="fs-5">6. Security Measures</h3>
        <p>
          We employ reasonable security measures to protect your personal
          information from unauthorized access, disclosure, alteration, and
          destruction.
        </p>

        <h3 className="fs-5">7. Your Rights</h3>
        <p>
          You have the right to access, correct, or delete your personal
          information. Please contact us if you have any concerns about your
          data.
        </p>

        <h3 className="fs-5">8. Policy Changes</h3>
        <p>
          We may update this Privacy Policy periodically. Any changes will be
          effective upon posting the updated policy on our website.
        </p>

        <h3 className="fs-5">9. Contact Information</h3>
        <p>
          For any questions or concerns regarding this Privacy Policy, please
          contact us at Theroyalhubs.ind@gmail.com
        </p>

        <h3 className="fs-5">10.Consent</h3>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
