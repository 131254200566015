import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

window.onscroll = function () {
    scrollFunction();
  };
  
  function scrollFunction() {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      document.getElementById("navBody").style.backgroundColor = "#27282b";
    } else {
      document.getElementById("navBody").style.backgroundColor = "";
    }
  }
function CollapseNavBar() {
  return (
    <Navbar collapseOnSelect expand="lg" className="fixed-top pt-0 pb-0" id="navBody" >
      <Container style={{padding:"0"}}>
        <Navbar.Brand href="#home"><div className="d-flex align-items-center justfy-content-center">
            <div className="navLogoTop1">
              <a
                class="navbar-brand"
                href="/"
                title="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
              >
                <img
                  src="/static/trh_logo.png"
                  alt="THE ROYAL HUBS"
                  id="logo"
                  className="navLogoInner"
                />
              </a>
            </div>
            <div className="navLogoTop2">
              <a
                class="navbar-brand"
                href="/"
                style={{ color: "#ffae4d" }}
                title="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
              >
                <img
                  src="/static/trh_logo_name.png"
                  alt="THE ROYAL HUBS"
                  id="logo"
                  className="navLogoInner"
                />
              </a>
            </div>
          </div></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ backgroundColor: "#f3d706" }}/>
        <Navbar.Collapse id="responsive-navbar-nav" >
          <Nav className="navbar-nav ms-auto navColor ">
            <Nav.Link className='navLink' href="./">Home</Nav.Link> 
            <Nav.Link className='navLink' href="/gallery">Photos</Nav.Link>
            <Nav.Link className='navLink' href="/videos">Videos</Nav.Link>
            <Nav.Link className='navLink' href="/foodmenu">Food Menu</Nav.Link>
            <Nav.Link className='navLink' href="/refund">Queries/Refund</Nav.Link>
            <Nav.Link className='navLink' href="/reviews">Reviews</Nav.Link>
            <Nav.Link className='navLink' href="/generalinstructions">Instructions</Nav.Link>
            <Nav.Link className='navLink' href="/about">About Us</Nav.Link>
            <Nav.Link className='navLink' href="/contact">Contact Us</Nav.Link>
            <Nav.Link  className='navLink' href="/booknow" ><button className='buttonNav'>Book now</button></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapseNavBar;