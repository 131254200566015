import React from "react";
import data from "./shortvideos.json";

function Video() {
  return (
    <>
      <div className="container-fluid">
        <div className="heroImage">
          <img className="contact-caraousel-img" src="/static/contact.JPG" />
          <div className="contact-title">
            <h2 className="contact-caraousel-title">Our Moment of Celeberations</h2>
            <p className="contact-carousel-para">
              ---- Scroll down to view more ----
            </p>
          </div>
        </div>
      </div>
      <h2
        className="contact-caraousel-title p-5 "
        style={{ color: "rgb(145, 128, 4)" }}
      >
       Video Gallery
      </h2>
      <div
        className="container"
        style={{
          marginBottom: "3%",
         
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {data.videos.map((item) => {
          return (
            <>
              <div style={{ width: "300px", height: "350px", margin: "10px" }}>
              <iframe
                  
                  style={{
                    width: "100%",
                    height: "100%",
                    borderTopRightRadius: "25px",
                    borderBottomLeftRadius: "25px",
                    boxShadow: "10px 10px 10px rgba(0,0,0,0.3)",
                    objectFit: "cover",
                  }}
                  src={item}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay;  clipboard-write; encrypted-media;
gyroscope; picture-in-picture;
web-share"
 allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"
                ></iframe>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default Video;
