import React from "react";
import "../App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
function Footer() {
  return (
    <div>
      {/* <!-- Footer start --> */}
      <div>
        {/* footer1 */}
        <div class="container-fluid footer1">
          <h3 class="footer-title">Looking for Private Theatre?</h3>
          <h4 class="footer-title2">Ready for celebrations?</h4>
          <div class="d-flex justify-content-center">
            <a href="/booknow" class="button">
              Book Now
            </a>
          </div>
        </div>

        {/* footer2 */}

        <div className="container">
          <div class="row footer2 ">
            <div class="col-md-4 footerLogoTop">
              <img
                src="/static/trh_full_logo_black.png"
                className="footerLogo"
                alt="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
              />
            </div>

            <div class="col-md-4 flex-center p-3">
              <h3 className="footer2Socialtext">Follow Us</h3>
              <div class="d-flex fs-1 gap-4 mt-2">
                <a href="https://www.instagram.com/theroyalhubs.in">
                <div  style={{height:"40px",width:"40px"}}>
              <img src="/static/instagramlogo.png"     style={{height:"100%",width:"100%"}} alt="" />
              </div>
                </a>
                <a href="https://www.facebook.com/theroyalhubs?mibextid=ZbWKwL">
                <div  style={{height:"40px",width:"40px"}}>
              <img src="/static/facebooklogo.png"     style={{height:"100%",width:"100%"}} alt="" />
              </div>
                </a>
                <a href="https://www.youtube.com/@TheRoyalHub-oh6uf">
                <div  style={{height:"40px",width:"40px"}}>
              <img src="/static/youtubelogo.png"     style={{height:"100%",width:"100%"}} alt="" />
              </div>
                </a>
              </div>

              <a href="./" className="footer2Socialtext2">
                <p>credits</p>
              </a>
            </div>

            <div class="col-md-4 footer2Contact">
              <h3 className="footer2ContactTxt">The Royal Hub</h3>
              <p style={{ color: "#333" }}>+91 6360845880</p>
              <p style={{ color: "#333" }}>Theroyalhubs.ind@gmail.com</p>
              <p style={{ color: "#333" }}>
                {" "}
                No 8, 2nd Floor, Deepak Raj Complex, Christ University Main
                Road, Rukmini Nagar, Nagasandra Post, Bengaluru-560073 <br/> Landmark: Opp. Sarojini Hospital
                & above D-Mart 
              </p>
              <p style={{ color: "#333" }}> </p>
            </div>
          </div>
        </div>

        {/* footer3*/}
        <div class="d-flex flex-wrap justify-content-between bg-dark p-2">
        <div className="d-flex flex-wrap gap-2" >
        <p style={{ color: "white",marginBottom:"0px" }}>
            Copyright © <span id="year"></span> The Royal Hub All Rights
            Reserved. |</p>
        
           <a href="/privacypolicy"  style={{ color: "white"   }} >Privacy Policy | </a> 
          
            <a href="/termsofuse" style={{ color: "white" }} >Terms of use |</a>
            <a href="/refund" style={{ color: "white" }} >Refund Policy</a>
          
        </div>
         
         


          <a className="footer3link" href="https://bubblesorttech.in/">
            Powered by BUBBLESORT TECH LLP.
          </a>
        </div>
      </div>
      {/* <!-- Footer Ends --> */}

      {/* whatsapp icon starts */}
      <div class="chat_icon animate__heartBeat " >
        <a href="https://wa.me/+916360845880">
          <img
          className="me-1 ms-1 mt-1"
            src="/static/whatsapp.png"
            style={{ width: "85%", height: "85%"}}
            alt="The Royal Hubs|Private Theatre|Entertainment & Event Management|Bengaluru"
          />
        </a>
      </div>
      {/* whatsapp icon ends */}
    </div>
  );
}

export default Footer;
