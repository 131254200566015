import React from "react";
import "../App.css";
import "animate.css";

function Carousel() {
  return (
    <div>
      <div
        id="carouselExampleIndicators"
        class="carousel slide carouselHome"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          {/* <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button> */}
        </div>
        <div class="carousel-inner carouselInner">
          <div class="carousel-item carouselItem active">
            <img
              src="/static/carousel3.jpg"
              class="d-lg-block  w-100"
              alt="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
            />
            <img
              src="/static/carousel31.jpg"
              class="d-lg-none w-100"
              alt="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
            />
            <div class="overlay-carousel carouselLabelParent  ">
              <div>
                <h2 class="carouselLabel animate__animated animate__zoomInDown ">
                  Entertainment with Celebrations
                </h2>
                <p className="text-center " style={{ color: "#f5f1ca" }}>
                  Call us/whatsapp us on 6360845880 to book a slot
                </p>
                <div className=" d-flex flex-column  justify-content-center align-items-center  ">
                  <button
                    className="carouselNumber "
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Call Us
                  </button>
                  
                  <a href="/booknow" style={{marginBottom:"10%"}}> <button
                    className="carouselNumber1 "
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Book Online
                  </button></a>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade w-100 "
            id="exampleModal"
            tabindex="1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body text-center">6360845880</div>
                <div className="d-flex  justify-content-center">
                <a href="/booknow">  <button  className='buttonNav '>Click here to book Online</button></a>
              
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item carouselItem">
            <img
              src="/static/carousel1.jpg"
              class="d-block w-100 "
              alt="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
            />
            <div class="overlay-carousel carouselLabelParent">
              <div>
                <h2 class="carouselLabel animate__animated animate__zoomInDown">
                  {" "}
                  Enjoy Private Mini Theatre
                </h2>
                <p className="text-center " style={{ color: "#f5f1ca" }}>
                Call us/whatsapp us on 6360845880 to book a slot
                </p>
                <div className=" d-flex  flex-column justify-content-center align-items-center gap-2">
                  <button
                    className="carouselNumber"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    {" "}
                    Call Us{" "}
                  </button>
                 
                  
                  <a href="/booknow" style={{marginBottom:"10%"}}> <button
                    className="carouselNumber1 "
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Book Online
                  </button></a>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item carouselItem">
            <img
              src="/static/carousel4.jpg"
              class="d-block w-100"
              alt="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
            />
            <div class="overlay-carousel carouselLabelParent">
              <div>
                <h2 class="carouselLabel animate__animated animate__zoomInDown">
                  Decorations
                </h2>
                <p className="text-center " style={{ color: "#f5f1ca" }}>
                Call us/whatsapp us on 6360845880 to book a slot
                </p>
                <div
                  className=" d-flex  flex-column justify-content-center align-items-center gap-2"
                 style={{marginBottom:"10%"}}
                >
                  <button
                    className="carouselNumber"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Call Us
                  </button>
                 
                  
                  <a href="/booknow" style={{marginBottom:"10%"}}> <button
                    className="carouselNumber1 "
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Book Online
                  </button></a>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="carousel-item carouselItem">
            <img
              src="/static/carousel2.jpg"
              class="d-block w-100"
              alt="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
            />
            <div class="overlay-carousel carouselLabelParent">
              <h2 class="carouselLabel animate__animated animate__zoomInDown"> Enjoy Delicious Food</h2>
            </div>
          </div> */}

          <div class="carousel-item carouselItem">
            <img
              src="/static/carousel5.jpg"
              class="d-block w-100"
              alt="The Royal Hubs|Private Tehatres|Entertainment & Event Management|Bengaluru"
            />
            <div class="overlay-carousel carouselLabelParent">
              <div>
                <h2 class="carouselLabel animate__animated animate__zoomInDown">
                  {" "}
                  Treasure Your Memories
                </h2>
                <p className="text-center " style={{ color: "#f5f1ca" }}>
                Call us/whatsapp us on 6360845880 to book a slot
                </p>
                <div
                  className=" d-flex  flex-column justify-content-center align-items-center"
    
                >
                  <button
                    className="carouselNumber"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Call Us
                  </button>            
                  <a href="/booknow" style={{marginBottom:"10%"}}> <button
                    className="carouselNumber1 "
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Book Online
                  </button></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
