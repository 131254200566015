import React from "react";

const About = () => {
  return (
    <>
   <div style={{ height: "80px",backgroundColor:"#27282b"}}></div>
      <div className="container-fluid" style={{ height: "5vh" }}>
        {/* <div className="heroImage">
          <img className="contact-caraousel-img" src="/static/about.JPG" />
          <div className="contact-title">
            <h2 className="contact-caraousel-title">About Us</h2>
            <p className="contact-carousel-para">---- Scroll down ----</p>
          </div>
        </div> */}
      </div>

      <div className="container">
        <div
          className="row align-items-md-center justify-content-center"
          style={{ color: "#918004" }}
        >

<div className="col-md-6 p-4 order-md-1 "   >
            <div className="" >
            <img
              src="/static/trh_full_logo.png"
              className="innerImg"
              style={{ objectFit: "cover" }}
            />
            </div>
          </div>

          <div className="col-md-6 p-4 order-md-0">
          <h1>About US</h1>
              <p style={{ color: "#918004", textAlign:"justify"}}>
                Welcome to 'The Royal Hubs', where we bring the magic of the big
                screen to your special moments! We specialize in providing
                private theaters for celebrations, creating unforgettable
                experiences for you and your guests. <br></br>
                <br></br><span style={{fontWeight:"bold"}}>Our Story</span> <br></br>At 'The Royal
                Hubs', we believe in the power of shared moments. Our journey
                began with a passion for creating unique, immersive celebrations
                that go beyond the ordinary. We understand that every occasion
                is special and deserves a touch of luxury, which is why we've
                dedicated ourselves to transforming private theaters into
                exclusive spaces for your joyous events.<br></br> 
                <br></br><span style={{fontWeight:"bold"}}>What Sets Us Apart</span><br></br>
                Exceptional Experiences: Our private theaters are not just
                venues; they are canvases for your celebrations. Immerse
                yourself in the enchantment of your favorite movies, TV shows,
                or presentations, surrounded by those you cherish.<br></br><br></br><span style={{fontWeight:"bold"}}> Personalized
                Service:</span> We take pride in offering tailored experiences. From
                birthdays and anniversaries to corporate gatherings, our team
                works closely with you to customize packages that match your
                vision and preferences.<br></br>
                <br></br><span style={{fontWeight:"bold"}}>Why Choose 'The Royal Hubs' </span><br></br>Unmatched
                Privacy: Enjoy the luxury of having the entire theater to
                yourself and your guests. No interruptions, no distractions –
                just pure celebration.<br></br>
                <br></br><span style={{fontWeight:"bold"}}> Cutting-Edge Technology:</span><br></br> Our theaters are
                equipped with state-of-the-art audio and visual systems,
                delivering an unparalleled cinematic experience.<br></br>
                <br></br><span style={{fontWeight:"bold"}}> Easy Booking:</span><br></br>
                Planning your celebration is a breeze with our user-friendly
                online booking system. Reserve your private theater, select your
                beverage options, and get ready for a memorable event. Join us
                at 'The Royal Hubs' as we redefine celebrations in the most
                cinematic way possible. Let's make your moments larger than
                life!
                <br></br><br></br>
              </p>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default About;
