import React from "react";

const Refund = () => {
  return (
    <>
      <div className="container-fluid">
        {/* <div className="heroImage">
          <img className="contact-caraousel-img" src="/static/about.JPG" />
          <div className="contact-title">
            <h2 className="contact-caraousel-title">Queries/Refunds ? </h2>
            <p className="contact-carousel-para">
              ---- Scroll Down To View More ----
            </p>
          </div>
        </div> */}
        <div style={{ height: "80px",backgroundColor:"#27282b"}}></div>
       <div className='container p-4 mt-4 mb-4 '>
       <h2> REFUND POLICY:</h2>
       </div>
      </div>
    </>
  );
};

export default Refund;
