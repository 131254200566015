import React from 'react'

function TermsOfUse() {
  return (
    <div>
    <div style={{ height: "80px", backgroundColor: "#27282b" }}></div>
    <div className="container p-4 mt-4 mb-4">
        <h2>TERMS OF USE:</h2>
        <p>Last Updated :20/01/2024</p>

        <p>These terms and conditions (the "Terms") of this User Agreement (as herein defined) govern Your access to and use of &nbsp; "The Royal Hubs"  &nbsp;that link to or reference this User Agreement and website (collectively, the "Site"). By accessing, browsing, crawling, scraping or in any way using the Site, You agree to the Terms set forth in this User Agreement, The Royal Hubs rules or policies referenced herein (collectively, the "User Agreement" ).</p>
        
        <p> <b>
        PLEASE READ THIS AGREEMENT CAREFULLY. THE TERMS OF THIS USER AGREEMENT ARE ACCEPTED BY YOUR USE OF THE SITE. If you are unwilling or unable to abide by this agreement, DO NOT USE THE SITE.
        </b></p>

    <p>
    (a) "User" is someone who accesses, browses, crawls, scrapes or in any way uses the Site. The terms "You", "Your" and "Yourself" refer exclusively to you, as a User of the Site. The terms "We", "Us", "Our", and "The Royal Hubs" refer exclusively to The Royal Hubs, Based in Bangalore, INDIA No 8, 2nd Floor, Deepak Raj Complex, Christ University Main Road, Rukmini Nagar, Nagasandra Post, Bengaluru-560073
Landmark: Opp. Sarojini Hospital & above D-Mart.  &nbsp; Each of “You”, “Your”, “Yourself” and “We”, “Us”, “Our” and “The Royal Hubs”, is a “Party” and together the “Parties”.
    </p>

    <p>
    (b) "Content" means text, images, and photos, audio, video and all other forms of data or communication. "Your Content" means Content that You submit or transmit to or through the Site, such as ratings, reviews, compliments, invitations and information that you display as part of your account profile. "User Content" means Content that Users submit or transmit to or through the Site. "The Royal Hubs Content" means Content that We create and make available on the Site. "Third Party Content" means Content that is made available on the Site by parties other than The Royal Hubs or its Users, such as data providers who license data to The Royal Hubs for use on the Site. "Site Content" means all of the Content that is made available on the Site, including Your Content, User Content, Third Party Content, and The Royal Hubs Content.
    </p>

    <p>
    Minors. If you are a minor (under the age of 18), you can use this service only with the consent and under the supervision of your parents or legal guardians. If you are a minor, please do not submit any personal information to this website.
    </p>

    <p>
      <b>IF YOU ARE 13 YEARS OR YOUNGER, PLEASE DO NOT USE THIS SITE OR ANY OF ITS SERVICES FOR ANY PURPOSE. YOU MUST BE AT LEAST 18 YEARS OF AGE TO  USE THIS WEBSITE.</b>  
    </p>

    <p>If you are still using our website, we heartily welcome you to The Royal Hubs Website. The use of this website is subject to the following terms of use:</p>

    <p>1.	The content of the pages of this website is for your general information and use only. We may modify this Agreement from time to time, for example, to reflect changes to the law or changes to the Site. You should look at the User Agreement regularly. Any progressions to the Client Arrangement that we cause will to be declared on this page. We will notify you of any modifications by making the most recent version available on the Site and displaying the date the modifications were last made on the Site.</p>

    <p>2.	Neither we nor any outsiders give any guarantee or assurance regarding the exactness, idealness, execution, fulfillment or reasonableness of the data and materials found or presented on this site for a specific reason. You acknowledge that such data and materials might contain incorrectness and errors and we explicitly prohibit obligation for any such errors or mistakes to the furthest reaches allowed by regulation.</p>

    <p>3.	You are sole responsible for the use of the information on this website and use of its materials is at your own risk, for which we are not responsible. It shall be your duty to make sure that any products, services or information available through this website meet your needs.</p>

    <p>4.	All labels repeated in this website which are not the property of, or authorized to, the administrator are recognized on the site.</p>

    <p>5.	This website contains content which is hold by or authorized to us. This material incorporates, however isn't restricted to, the plan, format, look, appearance and designs. Except as permitted by the copyright notice included in these terms and conditions, no reproduction is permitted.</p>

    <p>6.	Unauthorized use of this website might lead to a case for harms or potentially be a criminal offense.</p>
    <p>7.	You may not create a link and connect to this site from some other site or record without the consent of The Royal Hubs on earlier composed assent.</p>

    <p>8.	There may be links to other websites on this website from time to time. To make it easier for you to provide additional information, we have provided these links.</p>

    <p>9.	Your utilization of this site and any debate emerging out of such utilization of the site is dependent upon the laws of India or other administrative power.</p>

   
    </div>
    </div>
  )
}

export default TermsOfUse